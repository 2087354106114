<template>
  <div class="article-page-view">
    <v-container fluid>
      <transition name="fade">
        <div v-if="isDataLoaded">
          <v-card flat max-width="616" class="mx-auto">
            <v-row no-gutters>
              <v-col cols="12">
                <div
                  class="sw-page-title"
                  :style="getTitleConfig"
                  v-if="model && model.article_title_visibility"
                >
                  {{ model.name }}
                </div>
              </v-col>
              <v-col v-if="model.article_subheading" cols="12">
                <div class="sw-page-subtitle" :style="getTitleConfig">
                  {{ model.article_subheading }}
                </div>
              </v-col>
              <v-col cols="12">
                <div v-html="model.body"></div>
              </v-col>
            </v-row>
          </v-card>
        </div>
      </transition>
    </v-container>
  </div>
</template>

<script>
export default {
  data: () => ({
    isDataLoaded: false,
    model: {
      id: null,
      name: "",
      body: "",
      group_plugin_id: null,
      categories: [],
      publish_at: null,
      publish_until: null,
      article_title_alignment: "left",
      article_title_visibility: true,
    },
  }),
  computed: {
    groupId() {
      return this.$store?.getters?.group?.id;
    },
    articleId() {
      return this.$route?.params?.article_id;
    },
    groupPluginId() {
      return this.$route?.params?.group_plugin_id;
    },
    appLanguage() {
      return this.$store?.getters?.appLanguage;
    },
    getTitleConfig() {
      return {
        "text-align": this.model.article_title_alignment || "left",
      };
    },
  },
  watch: {
    articleId: {
      immediate: true,
      handler() {
        this.getGroupArticle();
      },
    },
    appLanguage: {
      handler() {
        this.getGroupArticle();
      },
    },
  },
  methods: {
    articleTransformer(item) {
      return {
        id: item.id,
        name: item.name,
        body: item.body,
        group_plugin_id: item.group_plugin_id,
        categories: item.categories,
        publish_at: item.publish_at,
        publish_until: item.publish_until,
        article_subheading: item.attributes.article_subheading,
        article_title_alignment:
          item.attributes.article_title_alignment || "left",
        article_title_visibility:
          item.attributes.article_title_visibility == null
            ? true
            : Boolean(item.attributes.article_title_visibility),
      };
    },
    getGroupArticle() {
      if (!this.groupId || !this.articleId) {
        return;
      }

      this.isDataLoaded = false;

      this.$http
        .get(`/groups/${this.groupId}/articles/${this.articleId}`, {
          params: {
            group_plugin_id: this.groupPluginId,
            with_attributes: 1,
            lang: this.appLanguage,
          },
        })
        .then((response) => {
          this.isDataLoaded = true;

          if (!response?.data?.data) return;

          this.model = this.articleTransformer(response.data.data);
        })
        .catch(() => {
          this.isDataLoaded = true;
        });
    },
  },
};
</script>

<style lang="scss">
.article-page-view {
  padding-top: 162px; /* sw-pt-11*/

  p.image-with-note {
    font-size: 10px;
    line-height: 1.6;
    margin-bottom: 25px;
  }

  p img {
    padding-top: 3px;
    padding-bottom: 5px;
  }
}

@media (max-width: 1300px) {
  .article-page-view {
    .container {
      padding-right: 30px;
      padding-left: 30px;
    }

    img.full-width-image {
      max-width: 100%;
      width: 100;
      height: auto;
    }
  }
}

@media (max-width: 900px) {
  .article-page-view {
    padding-top: 130px;

    .container {
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
</style>
